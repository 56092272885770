import React, { memo, forwardRef } from "react";
import Seo from "../components/SEO";

const PageLayout = memo(forwardRef((props, ref) => {
    const { title, description, meta, image } = props.seo;

    return (
        <>
            <Seo
                title={title}
                description={description}
                meta={meta}
                image={image}
            />

            <main style={{ margin: "50px 0" }} ref={ref}>{props.children}</main>
        </>
    )
}));

export default PageLayout;